import React, { useState, useContext, useEffect, useRef } from 'react'
import { useStaticQuery, graphql, Link } from 'gatsby'
import styled, { css } from 'styled-components'
import ProgressiveImage from 'react-progressive-image'
import tw from 'tailwind.macro'
import { useMount } from 'react-use'
import { sliderContext } from '../components/ImageSlider/SliderProvider'
import { IsMobileContext } from '../context/IsMobileContext'
import { motion, AnimatePresence } from 'framer-motion'

import { Layout, Block, PageSection, Nav, ImageSlider } from '../components'
import { media, isClient } from '../styles/utils'
import { container, padding, bgImage, type } from '../styles/global'

const DefaultPage = props => {
    const { previewData, pageData, pageContext } = props
    const data = previewData ||
        pageData || { ...pageContext, ...pageContext.acf }
    const [showToggle, setShowToggle] = useState(false)
    const [splash, setSplash] = useState(false)
    const { active, setActive } = useContext(sliderContext)
    const { isMobile } = useContext(IsMobileContext)

    // Animation defaults
    const duration = 0.35;
    const variants = {
        initial: {
            opacity: 0,
        },
        animate: {
            opacity: 1,
            transition: {
                duration: duration,
                delay: duration,
            },
        },
        exit: {
            opacity: 0,
            transition: {
                duration: duration,
                delay: duration,
            },
        },
    }      

    useEffect(() => {
        if (isMobile && data.slug === '/') {
            setSplash(false)
            setShowToggle(false)
        }
    }, [isMobile])

    useMount(() => {
        if (!isMobile && data.slug === '/') {
            setSplash(true)
            setShowToggle(true)
        }

        if (!active) {
            setActive(true)
        }
    })

    const renderDefaultContent = () => {
        return (
            <Content
                className={'tiny-mce'}
                key={data?.content_position_x}
                {...variants}
                style={{
                    marginTop: isMobile && '2.5rem'
                }}
                contentPosition={data?.content_position}
                contentPositionX={data?.content_position_x}
                dangerouslySetInnerHTML={{
                    __html: data.content,
                }}
            />
        )
    }

    const renderHomeContent = () => {
        return (
            <>
                {isMobile && <ImageSlider type={'inner'} />}
                {!isMobile && renderDefaultContent()}
            </>
        )
    }

    const renderContent = () => {
        if (!data.content) return 

        return (
            <>
                {data.slug && data.slug === '/' && renderHomeContent()}
                {data.slug && data.slug !== '/' && renderDefaultContent()}
            </>
        )
    }

    const toggleSplash = () => {
        setSplash(!splash)
    }

    return (
        <Layout meta={data && data.seo}>
            <Wrapper>
                {isMobile && (
                    <Content
                        contentPosition={'top'}
                        contentPositionX={'left'}
                    >
                        Fjura
                    </Content>
                )}
                <AnimatePresence>
                    {!splash && renderContent()}
                </AnimatePresence>
                <ContentToggle
                    style={{
                        opacity: showToggle ? 1 : 0,
                        display: showToggle ? 'flex' : 'none',
                    }}
                    onMouseEnter={toggleSplash}
                    onClick={toggleSplash}
                    splash={splash}
                >
                    {splash ? 'Fjura' : 'Fjura means flower.'}
                </ContentToggle>
                
                <AnimatePresence>
                    {!splash && (
                        <motion.div
                            key={'nav'}
                            {...variants}
                        >
                            <Nav type={data?.slug !== 'policies' && data?.slug !== 'shipping-rates' ? 'default' : 'store'} slug={data?.slug} />
                        </motion.div>
                    )}
                </AnimatePresence>
            </Wrapper>
        </Layout>
    )
}

// Shared

const Heading = styled.div``
const Subheading = styled.div``
const Description = styled.div``
const Info = styled.div``
const ExternalLink = styled.a``

const Image = styled(ProgressiveImage)`
    overflow: hidden;
`

const BGImage = styled.div`
    background-image: url(${props => props.image});
    ${bgImage};
    transition: opacity 0.45s ease;
`

// Layout

const Wrapper = styled.div`
    ${tw`w-full h-full flex flex-col`};
`

// Content Toggle (home only)

const ContentToggle = styled.div`
    ${tw`absolute flex flex-col justify-center`};
    top: 50%;
    transform: translateY(-50%);
    /* cursor: pointer; */

    ${media.tab`
        ${tw`static`};
        transform: none;
    `}
`

// Content

const Content = styled(motion.div)`
    margin-top: auto;

    &.tiny-mce {
        overflow-y: auto;
    }

    ${props => {
        if (props.contentPosition === 'top')
            return css`
                margin-top: initial;
                margin-bottom: auto;
            `
    }}
    ${props => {
        if (props.contentPositionX === 'right')
            return css`
                padding-left: 50%;
            `
    }}
`

export default DefaultPage
